/* *{
  box-sizing: border-box;  
  padding: 0;
  margin: 0;
 
}   */

 .error__loc {
  font-size: x-large;
  color: rgb(0, 26, 255);
  font-weight: bold;
  position: fixed;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%);
}
.main-body-weather{
  min-height: 50vh;
  position: relative;
 /*  height: 100%; */
  width: 100%;
  background-image: url('./assets/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
} 

/* body::-webkit-scrollbar {
  display: none;
} */
