.loader {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    background-color: #00000087;
    opacity: 0.92;
    z-index: 99999;
  }
  
  .spinner {
    width: 48px;
    height: 48px;
    border: 2px solid red;
    border-bottom-color: #fff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }