.ticket-outer-class{
    width:567px;
    height:208px;
    border:1px solid red;
}

.img-div{
    width:200px;
    height: 206px;
    background-color: black;
    border-color: 2px solid red;
}

.outer-ticket-content-div{
    width:465px;
    height: 206px;
}

.logo-div-tiket{
    width:367px;
    height: 65px;
    background-color: black;
}

.ticket-div-info{
    display: flex;
    align-items: center;
    width:367px;
    height: 65px;
}

.inside-content-div{
    padding: 15px;
    font-size: 20px;
}

.qr-code-div{
    margin-left: 5px;
}

.company-info-div{
    width:367px;
    height: 76px;
    background-color: black;
}